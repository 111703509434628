import { FC, ReactNode, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { navs } from 'constants/navs';
import { appRoutes, authRoutes, navigation } from 'constants/routes';
import { useAppDispatch, useAppSelector, useBreakPoint } from 'hooks';
import { authActions, authSelector } from 'store';
import { TNavItem } from 'types';

import { ControlPanelMenu } from 'components/ControlPanelMenu';
import { MobileNavigation } from 'components/MobileNavigation';
import { ConfirmModal } from 'components/ui/Modal/ConfirmModal';

import { EUserRole } from '../../constants/profile';

import { CreateNotificationModal } from './CreateNotificationModal';
import HeaderLink from './HeaderLink';
import { ProfileModal } from './ProfileModal';
import { StartStreamButton } from './StartStreamButton';
import { SupportModal } from './SupportModal';

import './style.scss';

interface IHeaderProps {
  isPageControlPanel?: boolean;
  leftContent?: ReactNode;
  centralContent?: ReactNode;
  rightButton?: ReactNode;
  сontentPanelMenu?: ReactNode;
  isStartStream?: boolean;
  resetOptions?: () => void;
}

export const Header: FC<IHeaderProps> = ({
  isPageControlPanel = true,
  leftContent,
  centralContent,
  rightButton,
  сontentPanelMenu,
  isStartStream = false,
  resetOptions,
}) => {
  const [isLogoutModalOpen, setLogoutModalOpen] = useState(false);

  const [isCreateNotificationModalOpen, setIsCreateNotificationModalOpen] =
    useState(false);

  const [isProfileModalOpen, setProfileModalOpen] = useState(false);
  const [isSupportModalOpen, setSupportModalOpen] = useState(false);

  const { role, username } = useAppSelector(authSelector);

  const dispatch = useAppDispatch();

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const breakPoint = useBreakPoint();

  const handleLogout = () => {
    dispatch(authActions.logout());
    setTimeout(navigate, 100, authRoutes.SIGN_IN);
  };

  const renderNavItem = (
    { link, title, icon, onClick }: TNavItem,
    idx: number
  ) => (
    <HeaderLink
      isActive={!!link && pathname.includes(link)}
      link={link}
      title={title}
      passiveIcon={icon || undefined}
      key={`link-${idx}`}
      onClick={onClick}
      containerClassName={breakPoint === 'mobile' ? 'px-[16px]' : ''}
    />
  );

  const handleClickLogo = () => {
    resetOptions?.();

    navigate(role ? navigation[role] : '/');
  };

  const isShowMobileNavigation =
    breakPoint === 'mobile' ||
    breakPoint === 'tablet' ||
    breakPoint === 'tablet-landscape';

  return (
    <>
      <div className="flex flex-col">
        <nav className="header flex justify-center">
          <div
            className="tpg-h3 cursor-pointer flex flex-row content-center"
            onClick={handleClickLogo}
          >
            <span>ASTRA</span>
            <span className="text-bright_product">S</span>
            {breakPoint !== 'mobile' && (
              <span className="header__username text-tpg_title">
                &nbsp; {username}
              </span>
            )}
          </div>
          {!isShowMobileNavigation && (
            <div className="header__links z-[11]">
              <div className="absolute left-1/2 -translate-x-1/2 flex flex-row">
                {role && navs[role].map((nav, ind) => renderNavItem(nav, ind))}
              </div>
            </div>
          )}
          {isShowMobileNavigation && (
            <MobileNavigation list={role && navs[role]} />
          )}
          {breakPoint !== 'mobile' && (
            <div className="flex flex-row gap-[16px]">
              <span
                className="cursor-pointer tpg-b1 text-tpg_base hover:text-bright_product transition"
                onClick={() => setSupportModalOpen(true)}
              >
                Техподдержка
              </span>
              <span
                className="cursor-pointer tpg-b1 text-tpg_base hover:text-bright_product transition"
                onClick={() => setProfileModalOpen(true)}
              >
                Профиль
              </span>
            </div>
          )}
        </nav>
        {isPageControlPanel && (
          <div className="w-full h-[48px] flex flex-row items-center justify-between bg-dark sticky top-0 z-10 px-[24px]">
            <div
              id="leftContent"
              className="flex flex-row items-center gap-[21px]"
            >
              {breakPoint !== 'mobile' && (
                <ControlPanelMenu
                  content={
                    <div className="flex flex-col text-nowrap w-full gap-[4px]">
                      {сontentPanelMenu}
                      {role === EUserRole.ROOT && (
                        <>
                          <span
                            className={cn(
                              'cursor-pointer tpg-c1 text-tpg_base hover:text-bright_product transition'
                            )}
                            onClick={() =>
                              navigate(`${appRoutes.ADMIN_ROOM_STATS}`)
                            }
                          >
                            Статистика по эфирам
                          </span>
                          <span
                            className={cn(
                              'cursor-pointer tpg-c1 text-tpg_base hover:text-bright_product transition',
                              {
                                '!text-bright_product':
                                  isCreateNotificationModalOpen,
                              }
                            )}
                            onClick={() =>
                              setIsCreateNotificationModalOpen(true)
                            }
                          >
                            Создать уведомление о тех. работах
                          </span>
                        </>
                      )}
                      <span
                        className={cn(
                          'cursor-pointer tpg-c1 text-tpg_base hover:text-bright_product transition',
                          {
                            '!text-bright_product': isLogoutModalOpen,
                          }
                        )}
                        onClick={() => setLogoutModalOpen(true)}
                      >
                        Выйти
                      </span>
                    </div>
                  }
                  className="!left-0 !top-[31px] !w-[324px]"
                />
              )}
              {leftContent && leftContent}
            </div>
            {centralContent && centralContent}
            {breakPoint !== 'mobile' ? (
              <div id="rightContent">
                {!rightButton && isStartStream ? (
                  <StartStreamButton />
                ) : (
                  !isStartStream && rightButton
                )}
                {rightButton && isStartStream && (
                  <div
                    className={cn('flex flex-row gap-[16px]', {
                      'gap-[5px]': breakPoint === 'tablet',
                    })}
                  >
                    {rightButton}
                    <StartStreamButton />
                  </div>
                )}
              </div>
            ) : (
              <ControlPanelMenu
                content={
                  <>
                    {!rightButton && isStartStream ? (
                      <StartStreamButton
                        classNameButton="!text-tpg_base !justify-start"
                        classNameContainer="!top-[50px] !right-[30px]"
                        isShowIcon={false}
                      />
                    ) : (
                      !isStartStream && rightButton
                    )}
                    {rightButton && isStartStream && (
                      <div className="flex flex-col">
                        {rightButton}
                        <StartStreamButton
                          classNameButton="!text-tpg_base !justify-start"
                          classNameContainer="!top-[50px] !right-[40px]"
                          isShowIcon={false}
                        />
                      </div>
                    )}
                    <span
                      className={cn('cursor-pointer tpg-c2 text-tpg_base', {
                        '!text-bright_product': isLogoutModalOpen,
                      })}
                      onClick={() => setSupportModalOpen(true)}
                    >
                      Техподдержка
                    </span>
                    <span
                      className={cn('cursor-pointer tpg-c2 text-tpg_base', {
                        '!text-bright_product': isLogoutModalOpen,
                      })}
                      onClick={() => setProfileModalOpen(true)}
                    >
                      Профиль
                    </span>
                    <span
                      className={cn('cursor-pointer tpg-c2 text-tpg_base', {
                        '!text-bright_product': isLogoutModalOpen,
                      })}
                      onClick={() => setLogoutModalOpen(true)}
                    >
                      Выйти
                    </span>
                  </>
                }
              />
            )}
          </div>
        )}
      </div>
      {isLogoutModalOpen && (
        <ConfirmModal
          title="Вы уверены, что хотите выйти?"
          description=""
          confirmText="Да, я хочу выйти"
          onConfirm={handleLogout}
          onClose={() => {
            setLogoutModalOpen(false);
          }}
        />
      )}
      {isProfileModalOpen && (
        <ProfileModal
          onClose={() => {
            setProfileModalOpen(false);
          }}
        />
      )}
      {isSupportModalOpen && (
        <SupportModal
          onClose={() => {
            setSupportModalOpen(false);
          }}
        />
      )}
      {isCreateNotificationModalOpen && (
        <CreateNotificationModal
          onClose={() => {
            setIsCreateNotificationModalOpen(false);
          }}
        />
      )}
    </>
  );
};
