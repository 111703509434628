export enum errorMessages {
  GET_PRIVILEGES_ERROR = 'Не удалось получить роль пользователя',
  GET_CLANS = 'Не удалось загрузить данные подразделений',
  CREATE_ROOM = 'Не удалось создать эфир',
  DELETE_ROOM = 'Не удалось удалить эфир',
  LOADING_ERROR = 'Ошибка загрузки',
  START_RECORDING = 'Запись эфиров временно отключена.',
  START_RECORDING_FAILED = 'Не удалось начать запись эфира',
  TERMINATE_RECORDING_FAILED = 'Не удалось завершить запись эфира',
  REGISTER_ERROR = 'Не удалось зарегистрировать пользователя',
  SET_CLAN_MEMBERS_BULK_ERROR = 'Не удалось выдать роли пользователям',
  DELETE_CLAN_MEMBER = 'Не удалось удалить пользователя',
  DELETE_MEMBER_ACCESSES = 'Не удалось удалить доступы пользователя',
  DELETE_RECORD = 'Не удалось удалить запись',
  GET_CLAN = 'Не удалось загрузить данные подразделения',
  CREATE_CLAN = 'Не удалось создать новое подразделение',
  UPDATE_CLAN = 'Не удалось обновить данные подразделения',
  DELETE_CLAN = 'Не удалось удалить выбранное подразделение',
  GET_ACCOUNT = 'Не удалось загрузить аккаунт',
  GET_ACCOUNTS = 'Не удалось загрузить список аккаунтов',
  UPDATE_ACCOUNT = 'Не удалось обновить аккаунт',
  DELETE_ACCOUNT = 'Не удалось удалить аккаунт',
  UPDATE_PASSWORD = 'Не удалось обновить пароль',
  UPDATE_PLAYER = 'Не удалось обновить данные пользователя',
  CREATE_NOTIFICATION = 'Не удалось создать уведомление',
  UPDATE_LOGIN = 'Не удалось обновить логин',
  UPDATE_ROLE = 'Не удалось изменить роль',
  UPDATE_MFA_REQUIRED = 'Не удалось установить значение двухфакторной аутентификации',
  VERIFY_PASSWORD = 'Не удалось подтвердить данные. Попробуйте снова',
  GET_AUDIT_LOGS = 'Не удалось загрузить аудит-логи',
  GET_POSSIBLE_VALUES = 'Не удалось загрузить кланы и пользователей',
}

export enum successMessages {
  ADD_STREAM = 'Эфир опубликован',
  ADD_ACCESS = 'Доступ добавлен',
  ADD_USER = 'Пользователь добавлен',
  SAVE_DATA = 'Данные сохранены',
  UPDATE_PASSWORD = 'Пароль пользователя изменен',
  UPDATE_LOGIN = 'Логин пользователя изменен',
  UPDATE_PLAYER = 'Данные пользователя обновлены',
  UPDATE_ROLE = 'Роль пользователя изменена',
  DELETE_CLAN = 'Подразделение удалено',
  DELETE_ACCESS = 'Доступ удалён',
  DELETE_ACCESSES = 'Доступы удалены',
  DELETE_PLAYER = 'Пользователь удалён',
  START_RECORDING = 'Начата запись эфира',
  TERMINATE_RECORDING = 'Запись эфира успешно завершена',
  COPY = 'Скопировано',
  CREATE_CLAN = 'Добавлено новое подразделение',
  DELETE_RECORD = 'Запись удалена',
  SET_CLAN_MEMBERS_BULK_SUCCESS = 'Доступы добавлены',
  CREATE_NOTIFICATION = 'Уведомление создано',
}

export enum warningMessages {
  ADD_NEW_PLAYER = 'Чтобы добавить нового пользователя, укажите доступ к подразделению',
  ADD_ACCESS = 'Для добавления доступа укажите подразделение и роль',
  UPDATE_LOGIN = 'Для смены логина сначала сгенерируйте новый пароль',
  INTERRUPT_STREAM = 'Видеопоток прерван',
  DESELECT_STREAM = 'Выбор потока отменен',
  FINISH_STREAM = 'Эфир завершен',
  ENTER_CLAN_NAME = 'Укажите название подразделения',
  PROVIDE_BASIC_INFO = 'Укажите всю основную информацию',
  PAUSE_STREAM = 'Эфир на паузе',
  RESUME_STREAM = 'Эфир возобновлён',
}
