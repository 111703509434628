import {
  ELogType,
  IAuditLog,
  IBaseAuditLogPayload,
  IClanAuditLogPayload,
  IClanMemberAuditLogPayload,
  IRoomAuditLogPayload,
  IUserAuditLogPayload,
} from '../interfaces/audit';
import { rolesReverseMap, rolesTranslateMap } from '../types/player';

import {
  getAccountLink,
  getActorLink,
  getClanLink,
  getRoomLink,
} from './accounts';

export const getRowDescription = (log: IAuditLog): string => {
  switch (log.log_type) {
    case ELogType.UserRegister: {
      const payload = log.payload as IUserAuditLogPayload;

      return `Пользователь ${getActorLink(
        payload.actorName,
        log.user_id
      )} зарегистрировал пользователя ${getAccountLink(
        payload.accountId,
        payload.accountName
      )}`;
    }

    case ELogType.UserLogin: {
      const payload = log.payload as IUserAuditLogPayload;

      return `Пользователь ${getActorLink(
        payload.actorName,
        log.user_id
      )} совершил вход в систему`;
    }

    case ELogType.UserChangePassword: {
      const payload = log.payload as IUserAuditLogPayload;

      return `Пользователь ${getActorLink(
        payload.actorName,
        log.user_id
      )} обновил пароль для пользователя ${getAccountLink(
        payload.accountId,
        payload.accountName
      )}`;
    }

    case ELogType.UserChangeEmail: {
      const payload = log.payload as IUserAuditLogPayload;

      return `Пользователь ${getActorLink(
        payload.actorName,
        log.user_id
      )} обновил логин для пользователя ${getAccountLink(
        payload.accountId,
        payload.accountName
      )}`;
    }

    case ELogType.UserBan: {
      const payload = log.payload as IUserAuditLogPayload;

      return `Пользователь ${getActorLink(
        payload.actorName,
        log.user_id
      )} забанил пользователя ${getAccountLink(
        payload.accountId,
        payload.accountName
      )}`;
    }

    case ELogType.UserUpdate: {
      const payload = log.payload as IUserAuditLogPayload;

      return `Пользователь ${getActorLink(
        payload.actorName,
        log.user_id
      )} обновил пользователя ${getAccountLink(
        payload.accountId,
        payload.accountName
      )}`;
    }

    case ELogType.ClanCreate: {
      const payload = log.payload as IClanAuditLogPayload;

      return `Пользователь ${getActorLink(
        payload.actorName,
        log.user_id
      )} создал подразделение ${getClanLink(payload.clanId, payload.clanName)}`;
    }

    case ELogType.ClanUpdate: {
      const payload = log.payload as IClanAuditLogPayload;

      return `Пользователь ${getActorLink(
        payload.actorName,
        log.user_id
      )} обновил подразделение ${getClanLink(
        payload.clanId,
        payload.clanName
      )}`;
    }

    case ELogType.ClanDelete: {
      const payload = log.payload as IClanAuditLogPayload;

      return `Пользователь ${getActorLink(
        payload.actorName,
        log.user_id
      )} удалил подразделение ${getClanLink(payload.clanId, payload.clanName)}`;
    }

    case ELogType.MfaSelfSet: {
      const payload = log.payload as IBaseAuditLogPayload;

      return `Пользователь ${getActorLink(
        payload.actorName,
        log.user_id
      )} установил двухфакторную аутентификацию`;
    }

    case ELogType.MfaRequiredSet: {
      const payload = log.payload as IUserAuditLogPayload;

      return `Пользователь ${getActorLink(
        payload.actorName,
        log.user_id
      )} установил двухфакторную аутентификацию пользователю ${getAccountLink(
        payload.accountId,
        payload.accountName
      )}`;
    }

    case ELogType.MfaRequiredReset: {
      const payload = log.payload as IUserAuditLogPayload;

      return `Пользователь ${getActorLink(
        payload.actorName,
        log.user_id
      )} сбросил двухфакторную аутентификацию пользователю ${getAccountLink(
        payload.accountId,
        payload.accountName
      )}`;
    }

    case ELogType.ClanMemberSet: {
      const payload = log.payload as IClanMemberAuditLogPayload;

      return `Пользователь ${getActorLink(
        payload.actorName,
        log.user_id
      )} добавил в подразделение ${getClanLink(
        payload.clanId,
        payload.clanName
      )} пользователя ${getAccountLink(
        payload.accountId,
        payload.accountName
      )} с ролью ${getRoleTitleById(payload.roleId)}`;
    }

    case ELogType.ClanMemberRemove: {
      const payload = log.payload as IClanMemberAuditLogPayload;

      return `Пользователь ${getActorLink(
        payload.actorName,
        log.user_id
      )} удалил пользователя ${getAccountLink(
        payload.accountId,
        payload.accountName
      )} из подразделения ${getClanLink(payload.clanId, payload.clanName)}`;
    }

    case ELogType.RoleAssignmentAdd: {
      const payload = log.payload as IClanMemberAuditLogPayload;

      return `Пользователь ${getActorLink(
        payload.actorName,
        log.user_id
      )} назначил роль ${getRoleTitleById(
        payload.roleId
      )} пользователю ${getAccountLink(
        payload.accountId,
        payload.accountName
      )}в подразделении ${getClanLink(payload.clanId, payload.clanName)}`;
    }

    case ELogType.RoomCreate: {
      const payload = log.payload as IRoomAuditLogPayload;

      return `Пользователь ${getActorLink(
        payload.actorName,
        log.user_id
      )} запустил эфир ${getRoomLink(
        payload.roomId
      )} в подразделении ${getClanLink(payload.clanId, payload.clanName)}`;
    }

    case ELogType.RoomDelete: {
      const payload = log.payload as IRoomAuditLogPayload;

      return `Пользователь ${getActorLink(
        payload.actorName,
        log.user_id
      )} завершил эфир ${getRoomLink(
        payload.roomId
      )} в подразделении ${getClanLink(payload.clanId, payload.clanName)}`;
    }

    case ELogType.RecordingStart: {
      const payload = log.payload as IRoomAuditLogPayload;

      return `Пользователь ${getActorLink(
        payload.actorName,
        log.user_id
      )} начал запись эфира ${getRoomLink(
        payload.roomId
      )} в подразделении ${getClanLink(payload.clanId, payload.clanName)}`;
    }

    case ELogType.RecordingEnd: {
      const payload = log.payload as IRoomAuditLogPayload;

      return `Пользователь ${getActorLink(
        payload.actorName,
        log.user_id
      )} завершил запись эфира ${getRoomLink(
        payload.roomId
      )} в подразделении ${getClanLink(payload.clanId, payload.clanName)}`;
    }

    default: {
      return 'Ошибка при получении описания';
    }
  }
};

export const getClanCell = (log: IAuditLog): string => {
  if (
    [
      ELogType.ClanCreate,
      ELogType.ClanCreate,
      ELogType.ClanUpdate,
      ELogType.ClanDelete,
      ELogType.ClanMemberSet,
      ELogType.ClanMemberRemove,
      ELogType.RoleAssignmentAdd,
      ELogType.RoomCreate,
      ELogType.RoomDelete,
    ].includes(log.log_type)
  ) {
    const payload = log.payload as IClanAuditLogPayload;

    return payload.clanName;
  }

  return log.payload.actorClanName;
};

export const getUsersCell = (log: IAuditLog): string => {
  if (
    [
      ELogType.UserRegister,
      ELogType.UserUpdate,
      ELogType.UserChangePassword,
      ELogType.UserChangeEmail,
      ELogType.UserBan,
      ELogType.MfaRequiredSet,
      ELogType.MfaRequiredReset,
      ELogType.ClanMemberSet,
      ELogType.ClanMemberRemove,
      ELogType.RoleAssignmentAdd,
    ].includes(log.log_type)
  ) {
    const payload = log.payload as IUserAuditLogPayload;

    return `${payload.actorName}, ${payload.accountName}`;
  }

  return `${log.payload.actorName}`;
};

const getRoleTitleById = (roleIdStr: string) => {
  const roleKey = rolesReverseMap[roleIdStr] || '';

  return rolesTranslateMap[roleKey];
};
